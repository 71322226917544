import React from "react";
import { Row, Col, Container, Button, Nav } from "react-bootstrap";
import Layout from "../components/academy-layout";
import Navbar from "../components/academy-layout/navbar";
import SEO from "../components/seo";
import calendar from "../assets/images/icons/calendar.svg";
import location from "../assets/images/icons/location.svg";
import price from "../assets/images/icons/price.svg";
import profile from "../assets/images/icons/profile.svg";
import eye from "../assets/images/icons/eye.png";
import afterEye from "../assets/images/icons/after-eye.png";
import medtech from "../assets/images/logo/medtech.png";
import siteminsel from "../assets/images/logo/siteminsel.svg";
import beAdvance from "../assets/images/logo/be-advance.png";
import risklick from "../assets/images/risklick-logo.png";
import bernUni from "../assets/images/logo/bern-uni.png";
import { Link } from "gatsby";
import Countdown from "react-countdown";
import { isMobile } from "react-device-detect";
import runCrisp from '../../scripts/crisp'

class EssentialProgram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minHeight: null,
    };
  }

  componentDidMount() {
    runCrisp()
    this.setState({ minHeight: window.innerHeight - 150 });

    window.addEventListener("resize", () => {
      this.setState({
        minHeight: window.innerHeight - 150,
      });
    });
  }

  render() {
    return (
      <Layout>
        <SEO />
        <div className={"academy white-bg essential-page"}>
          {/*first section*/}
          <div className={"essential-program-top-section"}>
            <Row>
              <Col
                className="fullHeight academy-intro-section essential-bg"
                style={{ height: this.state.minHeight }}
              >
                <Navbar />
                <div className="page-container">
                  <div
                    className={"first-section essential-program-top-content"}
                  >
                    <p>
                      clinical trial protocol design and optimization program
                    </p>
                    <p>
                      This program is aimed at professionals wanting to learn
                      the principles of clinical trial protocol design and
                      optimization using state-of-the-art, data-driven
                      solutions.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            {/* Timer section */}
            <Row>
              <Col className="timer">
                <Countdown
                  date="2022-10-31T09:00:00"
                  renderer={(props) => {
                    return (
                      <p>
                        {props.days} <sub>{isMobile ? "D" : "Days"}</sub> :{" "}
                        {props.hours} <sub>{isMobile ? "H" : "Hours"}</sub> :{" "}
                        {props.minutes} <sub>{isMobile ? "M" : "Minutes"}</sub>{" "}
                        : {props.seconds}{" "}
                        <sub>{isMobile ? "S" : "Seconds"}</sub>
                        {isMobile ? (
                          <p>Left to start</p>
                        ) : (
                          <span>Left to start</span>
                        )}
                      </p>
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
          {/* Essential Program section */}
          <div className="page-container">
            <Container>
              <Row className="essential-program-container sized-container">
                <Col lg={8}>
                  <p>
                    Data-driven clinical trial protocol design and optimization
                  </p>
                  <p>Target audience:</p>
                  <p>
                    This course is aimed at those wanting to learn the
                    principles of clinical trial protocol design and
                    optimization using state-of-the-art approaches. The
                    day-and-a-half course consists of lecture series along with
                    practical exercises and interactive sessions.
                  </p>
                  <p>Trainers:</p>
                  <p>
                    The clinical trial academy is composed of experts from
                    academia and industry who hold operational, managerial, and
                    regulatory clinical trial expertise. Such diversity in
                    background and experience provides a wealth of insight into
                    how to create a sound trial protocol.
                    <small>
                      <b>
                        “At the end of the course, you will receive a
                        confirmation of participation. ”
                      </b>
                    </small>
                  </p>
                </Col>
                <Col className="icons-container" lg={{ span: 4 }}>
                  <Row>
                    <img src={profile} alt="" />
                    <span>Min: 5 / Max: 12 Participants</span>
                  </Row>
                  <Row>
                    <img src={price} alt="" />
                    <span>1,900 CHF</span>
                  </Row>
                  <Row>
                    <img src={calendar} alt="" />
                    <span>31st October - 1st November 2022 </span>
                  </Row>
                  <Row>
                    <img src={location} alt="" />
                    <span>Bern</span>
                  </Row>
                  <Row>
                    <Link
                      to="https://forms.gle/g9sAunvbGfXDxFGn6"
                      className="link-no-style"
                    >
                      <Nav.Link
                        as="span"
                        className={"register-btn academy-btn mr5"}
                      >
                        Register Now
                      </Nav.Link>
                    </Link>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          {/* advertise */}
          <div className="course-items">
            <div className="page-container">
              <Container>
                <div className="course-items__days-container">
                  <Row>
                    <Col
                      xs={{ span: 10, offset: 1 }}
                      md={{ span: 10, offset: 1 }}
                      xl={{ span: 7, offset: 0 }}
                      className="day day1"
                    >
                      <div className="left-hexagon hexagon">
                        <div className="red-hexagon" id="hexagon">
                          Day 1
                        </div>
                      </div>
                      <p>
                        <img src={eye} alt="" />
                        Clinical rial: Theory & reminder
                      </p>
                      <ul>
                        <li>Phases of clinical trials</li>
                        <li>Roles and responsibilities in trials</li>
                        <li>Role of protocol in trial conduct</li>
                      </ul>

                      <Col md={{ span: 6, offset: 3 }} className="separator" />

                      <p className="pt0">Networking lunch</p>

                      <Col md={{ span: 6, offset: 3 }} className="separator" />

                      <p>
                        <img src={afterEye} alt="" />
                        Clinical trial: Interactive design
                      </p>
                      <ul>
                        <li>Trial protocol: Endpoints</li>
                        <li>Trial protocol: Eligibility criteria</li>
                        <li>
                          Protocol writing: strategy, style, and readabilities
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={{ span: 10, offset: 1 }}
                      md={{ span: 10, offset: 1 }}
                      xl={{ span: 7, offset: 5 }}
                      className="day day2"
                    >
                      <div className="right-hexagon hexagon">
                        <div className="blue-hexagon" id="hexagon">
                          Day 2<small>Half-day</small>
                        </div>
                      </div>
                      <p>
                        <img src={eye} alt="" />
                        Data-driven trial groundwork
                      </p>
                      <ul>
                        <li>Interactive data-driven protocol design</li>
                        <li>Interactive data-driven protocol optimization</li>
                      </ul>

                      {/* <Col md={{ span: 6, offset: 3 }} className="separator" />

                      <p>
                        <img src={afterEye} alt="" />
                        Clinical study: Essential documents
                      </p>
                      <ul>
                        <li>
                          Clinical study required documents and guidelines
                        </li>
                        <li>Clinical study budget</li>
                        <li>Role of protocol in trial conduct</li>
                        <li>Protocol writing</li>
                        <li>Interactive session on protocol writing</li>
                      </ul> */}
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col
                      xs={{ span: 10, offset: 1 }}
                      md={{ span: 10, offset: 1 }}
                      xl={{ span: 7, offset: 0 }}
                      className="day day1 mb40"
                    >
                      <div className="left-hexagon hexagon">
                        <div className="red-hexagon" id="hexagon">
                          Day 3
                        </div>
                      </div>
                      <p>
                        <img src={eye} alt="" />
                        Data-driven trial protocol development
                      </p>
                      <ul>
                        <li>Protocol development methodology</li>
                        <li>Trial protocol I – hypothesis and background</li>
                        <li>
                          Trial protocol II – Eligibility criteria and visits
                        </li>
                      </ul>

                      <Col md={{ span: 6, offset: 3 }} className="separator" />

                      <p>
                        <img src={afterEye} alt="" />
                        Data-driven trial protocol optimization
                      </p>
                      <ul>
                        <li>
                          Trial protocol III – Endpoints and events reporting
                        </li>
                        <li>Protocol optimization and novel methodologies</li>
                        <li>
                          Interactive session: dos and dons on protocol
                          development
                        </li>
                      </ul>

                      <Col md={{ span: 6, offset: 3 }} className="separator" />

                      <p className="">
                        Key opinion leader presentation and networking
                      </p>
                    </Col> */}
                  {/*<Col md={7}>*/}
                  {/*  <Row className="icons-container">*/}
                  {/*    <Col md={{span: "auto"}}>*/}
                  {/*      <img src={profile} alt=""/>*/}
                  {/*    </Col>*/}
                  {/*    <Col md={{span: "auto"}}>*/}
                  {/*      <p>Lorem ipsum dolor sit</p>*/}
                  {/*      <a href="">https://risklick.ch/Essential.program/q2341hj/hilaumjh./jjkstyyh</a>*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*</Col>*/}
                  {/* </Row> */}
                </div>
              </Container>
            </div>
          </div>

          {/* logos */}
          <div className="page-container">
            <Container>
              <Row className="logo-container sized-container">
                <div className="col-sm-12 col-lg-3">
                  <img src={medtech} alt="" />
                </div>
                <div className="col-sm-12 col-lg-3">
                  <img src={siteminsel} alt="" />
                </div>
                <div className="col-sm-12 col-lg-2">
                  <img src={beAdvance} alt="" />
                </div>
                <div className="col-sm-12 col-lg-2">
                  <img src={risklick} alt="" />
                </div>
                <div className="col-sm-12 col-lg-2">
                  <img src={bernUni} alt="" />
                </div>
              </Row>
            </Container>
          </div>
        </div>
      </Layout>
    );
  }
}

export default EssentialProgram;
